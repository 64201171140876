.elsa-background {
  width: 100%;
  height: 80vh;
  background-repeat: no-repeat;
}

.elsa-book-text {
  padding: 30px;
  min-width: 200px;
  max-width: 400px;
}
.elsa-bok-container {
  width: 20vw;
}

.elsa-bok-container-landscape {
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding-top: 100px;
  justify-content: space-around;
  padding-bottom: 100px;
  flex-wrap: wrap;
  width: fit-content;
  margin: auto;
}

.elsa-bok-variety {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 40px;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 1vw;
  padding-right: 1vw;
  flex-wrap: wrap;
  margin-bottom: 200px;
}

.elsa-bok-mynd {
  min-width: 350px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: auto;
  padding: 2vw;
  height: 70vh;
}

.elsa-front {
}

.elsa-main-text {
  position: absolute;
  top: 60vh;
  left: 20vw;
  font-family: "Roboto";
  font-size: 25px;
  color: white;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.3), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1280px) {
  .elsa-main-text {
    left: 10vw;
    font-size: 17px;
  }
}

.who-are-we {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px;
  flex-wrap: wrap;
}

.sonn-checkboxes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 40px;
  padding-bottom: 30px;
}
input[type="checkbox"] {
  accent-color: #064400;
}

.elsa-order {
  color: green;
  font-size: 20px;
}
.mobile-navigation-icon {
  height: 70px;
}

.elsa-social {
  height: 40px;
  margin-bottom: 100px;
}

.elsa-social-blehh {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
}
